











import Vue, { VueConstructor } from 'vue';
import { Realtime, Types as AblyTypes } from 'ably';
import { Bus } from '@/main';
import { Api } from '@/http';
import RealtimeSubscriber from './RealtimeSubscriber.vue';

interface TypeFix {
  token: (params: AblyTypes.TokenParams, callback: any) => Promise<void>,
}

export default (Vue as VueConstructor<Vue & TypeFix>).extend({
  components: {
    RealtimeSubscriber,
  },
  data() {
    return {
      client: new Realtime({
        autoConnect: true,
        authCallback: this.token,
        log: {
          level: 2,
        },
      }),
      events: [] as any[],
      subscribedChannels: [] as string[],
    };
  },
  beforeDestroy() {
    this.client.close();
  },
  methods: {
    subscriber(channel: string): (msg: any) => void {
      return (msg: any) => Bus.$emit(`realtime:${channel}`, msg);
    },
    async token(
      params: AblyTypes.TokenParams,
      callback: (e: any|Error, t?: any) => void,
    ): Promise<void> {
      const res = await Api().post('realtime').catch(callback);

      if (!res) return;

      const token = res.data.data;

      callback(null, token);

      // Subscribe to all channels this token can subscribe to
      // User Feed: user:<user-id>
      // Business Feeds: business:<business-id>

      const capability = JSON.parse(token.capability);

      Object.keys(capability).forEach((channel) => {
        if (capability[channel].includes('subscribe')) {
          // Only subscribe if not subscribed yet
          if (this.subscribedChannels.includes(channel)) return;

          this.client.channels
            .get(channel)
            .subscribe(
              this.subscriber(channel) as any,
            );

          this.subscribedChannels.push(channel);
        }
      });
    },
  },
  render(h: CallableFunction): any {
    return this.subscribedChannels.map((channel) => h(RealtimeSubscriber, {
      propsData: {
        channelId: channel.split(':')[1],
        channelType: channel.split(':')[0],
      },
    }));
  },
});
