
import Vue from 'vue';
import { Bus } from '@/main';
import { ApiResource } from '@/types';
import {
  registerAccountSubmodules,
  registerBusinessCommissionSubmodules,
  registerBusinessSubmodules,
  registerCommissionSubmodules,
} from '@/store';
import flatten from '@/util/flatten';
import { ToastMessageOptions } from '@/plugins/toast';
import { Location } from 'vue-router';

export interface RealtimeMessage {
  id: string,
  name: 'event',
  data: ApiResource.Event,
}

const PRIMARY_ACTION = 'is-link is-fullwidth font-semibold';
const PRIMARY_TOAST = 'is-link';

export default Vue.extend({
  props: {
    channelId: {
      type: String,
      required: true,
    },
    channelType: {
      type: String,
      required: true,
    },
  },
  mounted() {
    Bus.$on(`realtime:${this.channelType}:${this.channelId}`, this.on);
  },
  beforeDestroy() {
    Bus.$off(`realtime:${this.channelType}:${this.channelId}`, this.on);
  },
  methods: {
    async on(message: RealtimeMessage): Promise<void> {
      if (process.env.NODE_ENV !== 'production') this.log(message);

      this.$store.commit('realtime/Event', message.data);

      let resource = null;
      const event = message.data;

      if (this.channelType === 'user') {
        await registerAccountSubmodules();
        this.$store.commit('account/PushContextEvent', event);

        // Event is in commission context
        if (event.context_commission_id) {
          this.$store.commit('account/PushCommissionEvent', event);

          await registerCommissionSubmodules();
          this.$store.commit('commission/event/Push', { data: event });

          resource = await this.fetchUserCommissionEventResource(
            event,
            event.context_commission_id,
          );
          this.toastEvent(event, resource?.data, this.getUserCommissionEventToastOptions(
            event, resource?.data, event.context_commission_id,
          ));
        } else {
          resource = await this.fetchUserEventResource(event);
          this.toastEvent(event, resource?.data, this.getUserEventToastOptions(
            event, resource?.data,
          ));
        }
      } else if (this.channelType === 'business') {
        await registerBusinessSubmodules();
        if (event.context_commission_id) {
          await registerBusinessCommissionSubmodules();
          this.$store.commit('business/commission/event/Push', message);

          resource = await this.fetchBusinessCommissionEventResource(
            event,
            event.context_commission_id,
          );
          this.toastEvent(event, resource?.data, this.getBusinessCommissionEventToastOptions(
            event, resource?.data, event.context_commission_id,
          ));
        } else {
          resource = await this.fetchBusinessEventResource(event);
          this.toastEvent(event, resource?.data, this.getBusinessEventToastOptions(
            event, resource?.data,
          ));
        }
      }
    },
    async fetchUserEventResource(
      event: ApiResource.Event,
    ): Promise<any> {
      switch (event.type) {
        case 'business.created':
          return this.$store.dispatch('business/Find', {
            BUSINESS_ID: event.resource_id,
          });

        case 'user.email_address.added':
        case 'user.email_address.verified':
          this.$store.dispatch('account/GetAccount');
          return this.$store.dispatch('account/email/Find', {
            EMAIL_ID: event.resource_id,
          });

        case 'user.email_address.changed':
          return this.$store.dispatch('account/GetAccount');

        case 'user.email_address.removed':
          return this.$store.commit('account/email/Remove', event.resource_id);

        default:
          return null;
      }
    },
    async fetchUserCommissionEventResource(
      event: ApiResource.Event,
      COMMISSION_ID: string,
    ): Promise<any> {
      switch (event.type) {
        case 'commission.file.created':
        case 'commission.file.uploaded':
          return this.$store.dispatch('commission/file/Find', {
            COMMISSION_ID,
            FILE_ID: event.resource_id,
          });
        case 'commission.file.deleted':
          return this.$store.commit('commission/file/Remove', event.resource_id);

        case 'commission.item.created':
        case 'commission.item.paid':
        case 'commission.item.refunded':
        case 'commission.item.forgiven':
          // Refresh affected files
          this.$store.dispatch('commission/file/List', {
            COMMISSION_ID,
            query: { item_id: event.resource_id },
          });
          return this.$store.dispatch('commission/item/Find', {
            COMMISSION_ID,
            ITEM_ID: event.resource_id,
          });

        case 'commission.message.created':
          return this.$store.dispatch('commission/message/Find', {
            COMMISSION_ID,
            MESSAGE_ID: event.resource_id,
          });

        case 'commission.requested':
        case 'commission.request.accepted':
        case 'commission.request.cancelled':
        case 'commission.request.rejected':
        case 'commission.status.active':
        case 'commission.status.disapproved_completion':
        case 'commission.status.finished':
        case 'commission.status.complete':
        case 'commission.status.pending_customer_action':
          return this.$store.dispatch('commission/Find', {
            COMMISSION_ID: event.resource_id,
          });

        default:
          return null;
      }
    },
    async fetchBusinessEventResource(
      event: ApiResource.Event,
    ): Promise<any> {
      const BUSINESS_ID = this.channelId;

      switch (event.type) {
        case 'account.updated':
          return this.$store.dispatch('business/GetAccount', { BUSINESS_ID });

        case 'business.created':
        case 'business.updated':
        case 'business.request_settings.updated':
        case 'business.slots.increased':
        case 'business.search.hidden':
        case 'business.search.published':
        case 'business.slots.decreased':
        case 'business.waitlist.slots.decreased':
        case 'business.waitlist.slots.increased':
        case 'standalone_account.authorized':
        case 'standalone_account.deauthorized':
          return this.$store.dispatch('business/Find', { BUSINESS_ID });

        case 'business.page.created':
        case 'business.page.updated':
          return this.$store.dispatch('business/Pages/Find', { BUSINESS_ID, PAGE_ID: event.resource_id });
        case 'business.page.deleted':
          return this.$store.commit('business/Pages/RemoveId', event.resource_id);

        case 'external_account.created':
          return this.$store.dispatch('business/ExternalAccounts/Find', {
            BUSINESS_ID,
            EXTERNAL_ACCOUNT_ID: event.resource_id,
          });
        case 'external_account.deleted':
          return this.$store.commit('business/ExternalAccounts/RemoveId', event.resource_id);

        default:
          return null;
      }
    },
    async fetchBusinessCommissionEventResource(
      event: ApiResource.Event,
      COMMISSION_ID: string,
    ): Promise<any> {
      const BUSINESS_ID = this.channelId;
      let resource;

      switch (event.type) {
        case 'commission.file.created':
        case 'commission.file.uploaded':
          return this.$store.dispatch('business/commission/file/Find', {
            BUSINESS_ID,
            COMMISSION_ID,
            FILE_ID: event.resource_id,
          });
        case 'commission.file.deleted':
          return this.$store.commit('business/commission/file/Remove', event.resource_id);

        case 'commission.item.created':
        case 'commission.item.paid':
        case 'commission.item.refunded':
        case 'commission.item.forgiven':
          return this.$store.dispatch('business/commission/item/Find', {
            BUSINESS_ID,
            COMMISSION_ID,
            ITEM_ID: event.resource_id,
          });

        case 'commission.message.created':
          return this.$store.dispatch('business/commission/messages/Find', {
            BUSINESS_ID,
            COMMISSION_ID,
            MESSAGE_ID: event.resource_id,
          });

        case 'commission.requested':
        case 'commission.request.accepted':
        case 'commission.request.cancelled':
        case 'commission.request.rejected':
        case 'commission.status.active':
        case 'commission.status.disapproved_completion':
        case 'commission.status.finished':
        case 'commission.status.complete':
        case 'commission.status.pending_customer_action':
          resource = await this.$store.dispatch('business/commission/Find', { BUSINESS_ID, COMMISSION_ID });

          break;

        default:
          break;
      }

      return resource;
    },
    toastEvent(event: ApiResource.Event, resource: any, options: ToastMessageOptions): void {
      if (!this.shouldToastEvent(event.type)) return;

      this.$toast({
        message: this.$t(`realtime.${event.type}.$${this.channelType}.0`, flatten({
          ...resource,
          previous_attributes: event.previous_attributes,
          additional_data: event.additional_data,
        })).toString(),
        position: 'bottom-left',
        classes: PRIMARY_TOAST,
        ...options,
      });
    },
    shouldToastEvent(type: string): boolean {
      return [
        // User Events
        'user.email_address.added',
        'user.email_address.verified',
        'user.email_address.changed',
        'user.email_address.removed',

        // Commission Events
        'commission.file.uploaded',
        'commission.file.deleted',

        'commission.item.created',
        'commission.item.paid',
        'commission.item.refunded',
        'commission.item.forgiven',

        'commission.message.created',

        'commission.requested',
        'commission.request.accepted',
        'commission.request.cancelled',
        'commission.request.rejected',
        'commission.status.active',
        'commission.status.disapproved_completion',
        'commission.status.finished',
        'commission.status.complete',
        'commission.status.pending_customer_action',

        // Business Events
        'business.updated',

        'business.request_settings.updated',

        'business.search.hidden',
        'business.search.published',
        'business.slots.increased',
        'business.slots.decreased',
        'business.waitlist.slots.decreased',
        'business.waitlist.slots.increased',

        'standalone_account.authorized',
        'standalone_account.deauthorized',

        'business.page.created',
        'business.page.updated',
        'business.page.deleted',

        'external_account.created',
        'external_account.deleted',
      ].includes(type);
    },
    getUserEventToastOptions(
      event: ApiResource.Event,
      resource: any,
    ): ToastMessageOptions {
      const actions: ToastMessageOptions['actions'] = [];

      if ([
        'user.email_address.added',
        'user.email_address.verified',
        'user.email_address.changed',
        'user.email_address.removed',
      ].includes(event.type)) {
        actions.push({
          label: this.$t('common.link.view-email-addresses').toString(),
          handler: this.toastRedirectHandler({
            name: 'account.emails',
            hash: resource ? `#${resource.id}` : undefined,
          }),
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      return { actions };
    },
    getUserCommissionEventToastOptions(
      event: ApiResource.Event,
      resource: any,
      COMMISSION_ID: string,
    ): ToastMessageOptions {
      const links = [];

      if (event.type.startsWith('commission.request') || event.type.startsWith('commission.status.')) {
        links.push({
          label: this.$t('common.link.view-commission').toString(),
          to: {
            name: 'commission',
            params: { COMMISSION_ID },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if (event.type.startsWith('commission.item.')) {
        links.push({
          label: this.$t('common.link.view-commission-item').toString(),
          to: {
            name: 'commission.item',
            params: {
              COMMISSION_ID,
              ITEM_ID: resource.id,
            },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if (event.type === 'commission.file.uploaded') {
        links.push({
          label: this.$t('common.link.view-commission-file').toString(),
          to: {
            name: 'commission.file',
            params: {
              COMMISSION_ID,
              FILE_ID: resource.id,
            },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if (event.type === 'commission.file.deleted') {
        links.push({
          label: this.$t('common.link.view-commission-files').toString(),
          to: {
            name: 'commission.files',
            params: { COMMISSION_ID },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if (event.type === 'commission.message.created') {
        links.push({
          label: this.$t('common.link.view-commission-messages').toString(),
          to: {
            name: 'commission.messages',
            params: { COMMISSION_ID },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      return { links };
    },
    getBusinessEventToastOptions(
      event: ApiResource.Event,
      resource: any,
    ): ToastMessageOptions {
      const links = [];

      if ([
        'business.search.hidden',
        'business.search.published',
        'business.slots.increased',
        'business.slots.decreased',
        'business.waitlist.slots.decreased',
        'business.waitlist.slots.increased',
      ].includes(event.type)) {
        links.push({
          label: this.$t('common.link.view-dashboard-settings').toString(),
          to: {
            name: 'dashboard.requests',
            params: { VANITY: this.channelId },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if (event.type === 'business.request_settings.updated') {
        links.push({
          label: this.$t('common.link.view-dashboard-settings').toString(),
          to: {
            name: 'dashboard.requests',
            params: { VANITY: this.channelId },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if ([
        'standalone_account.authorized',
        'standalone_account.deauthorized',
      ].includes(event.type)) {
        links.push({
          label: this.$t('common.link.view-dashboard-connect').toString(),
          to: {
            name: 'dashboard.connect',
            params: { VANITY: this.channelId },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if ([
        'business.page.created',
        'business.page.updated',
      ].includes(event.type)) {
        links.push({
          label: this.$t('common.link.view-dashboard-page').toString(),
          to: {
            name: 'dashboard.page',
            params: {
              VANITY: this.channelId,
              pageId: resource.id,
            },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });

        // TODO: Add link to view directly on profile
      }

      if (event.type === 'business.page.deleted') {
        links.push({
          label: this.$t('common.link.view-dashboard-pages').toString(),
          to: {
            name: 'dashboard.pages',
            params: { VANITY: this.channelId },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if ([
        'external_account.created',
        'external_account.deleted',
      ].includes(event.type)) {
        links.push({
          label: this.$t('common.link.view-dashboard-external-accounts').toString(),
          to: {
            name: 'dashboard.external-accounts',
            params: { VANITY: this.channelId },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      return { links };
    },
    getBusinessCommissionEventToastOptions(
      event: ApiResource.Event,
      resource: any,
      COMMISSION_ID: string,
    ): ToastMessageOptions {
      const links = [];

      if (event.type.startsWith('commission.request') || event.type.startsWith('commission.status.')) {
        links.push({
          label: this.$t('common.link.view-commission').toString(),
          to: {
            name: 'dashboard.commission',
            params: {
              VANITY: this.channelId,
              COMMISSION_ID,
            },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if (event.type.startsWith('commission.item.')) {
        links.push({
          label: this.$t('common.link.view-commission-item').toString(),
          to: {
            name: 'dashboard.commission.item',
            params: {
              VANITY: this.channelId,
              COMMISSION_ID,
              ITEM_ID: resource.id,
            },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if (event.type === 'commission.file.uploaded') {
        links.push({
          label: this.$t('common.link.view-commission-file').toString(),
          to: {
            name: 'dashboard.commission.file',
            params: {
              VANITY: this.channelId,
              COMMISSION_ID,
              FILE_ID: resource.id,
            },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if (event.type === 'commission.file.deleted') {
        links.push({
          label: this.$t('common.link.view-commission-files').toString(),
          to: {
            name: 'dashboard.commission.files',
            params: {
              VANITY: this.channelId,
              COMMISSION_ID,
            },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      if (event.type === 'commission.message.created') {
        links.push({
          label: this.$t('common.link.view-commission-messages').toString(),
          to: {
            name: 'dashboard.commission.messages',
            params: {
              VANITY: this.channelId,
              COMMISSION_ID,
            },
          },
          classes: PRIMARY_ACTION,
          dismiss: true,
        });
      }

      return { links };
    },
    toastRedirectHandler(route: Location) {
      return () => {
        this.$router.push(route).catch(console.error);
      };
    },
    log(message: RealtimeMessage): void {
      console.group('[Realtime]');
      console.info('Received new message.');
      console.debug(`Channel: "${this.channelType}:${this.channelId}"`);
      console.debug(`Event Type: "${message.data.type}"`);
      console.debug(`Event ID: "${message.data.id}"`);
      console.table({
        channel: `${this.channelType}:${this.channelId}`,
        ...message.data,
      }, [
        'channel', 'type', 'id', 'resource_id', 'resource_type', 'context_commission_id',
      ]);
      console.debug(message);
      console.groupEnd();
    },
  },
  render: (h) => h(),
});
