function isObject(obj: any): obj is object {
  return typeof obj === 'object' && obj !== null;
}

export default function flatten(obj: any): object {
  const delimiter = '.';
  const limit = 5;
  const flat: any = {};

  function step(o: any, pre?: string, recur = 1): void {
    Object.keys(o).forEach((key: string) => {
      const v = o[key];
      const k = pre ? `${pre}${delimiter}${key}` : key;

      if (isObject(v) && recur < limit) {
        return step(v, k, recur + 1);
      }

      flat[k] = v;

      return void 0;
    });
  }

  if (isObject(obj)) step(obj);

  return flat;
}
